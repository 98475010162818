import { create, GetState, SetState } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { createAnalysisSlice, TAnalysisState } from './slice/analysisSlice'
import { createInputSclie, TInputSlice } from './slice/inputSlice'
import { createManagementSlice, TManagementSlice } from './slice/managementSlice'
import { createMessageSlice, TMessageSlice } from './slice/messageSlice'
import { createStoreSlice, TStoreState } from './slice/storeSlice'
import { KEY_LOCALSTORAGE } from '@/constant/text'

interface TStore extends TStoreState, TAnalysisState, TMessageSlice, TInputSlice, TManagementSlice {}

const useStore = create<TStore>()(
    devtools(
        persist(
            (set, get) => ({
                ...createStoreSlice(set as SetState<TStoreState>, get as GetState<TStoreState>),
                ...createAnalysisSlice(set as SetState<TAnalysisState>, get as GetState<TAnalysisState>),
                ...createMessageSlice(set as SetState<TMessageSlice>, get as GetState<TMessageSlice>),
                ...createInputSclie(set as SetState<TInputSlice>, get as GetState<TInputSlice>),
                ...createManagementSlice(set as SetState<TManagementSlice>, get as GetState<TManagementSlice>),
            }),
            {
                name: KEY_LOCALSTORAGE.BI_storage,
            },
        ),
        {
            name: KEY_LOCALSTORAGE.BI_storage,
        },
    ),
)

export default useStore
